import * as React from "react";
import { Countdowns } from "../../data/Countdowns";
import { RocketLaunch } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";

interface CountdownProps {
  content: string;
  link: string;
  title: string;
  date: string;
}

export function CountdownCards() {
  return (
    <>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "0",
        }}
      >
        {Countdowns.map((article: CountdownProps, i: number) => {
          return (
            <ListItemButton component="a" href={`${article.link}`} key={i}>
              <Paper
                variant="outlined"
                sx={{ maxWidth: "40rem", minWidth: "22rem" }}
              >
                <Box sx={{ bgcolor: "secondary.main", height: ".75rem" }}></Box>
                <Box sx={{ bgcolor: "accent.main", height: ".25rem" }}></Box>
                <ListItem
                  button
                  divider={true}
                  key={i}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <ListItemText
                    sx={{ fontWeight: "bold" }}
                    primary={`${article.title}`}
                    primaryTypographyProps={{
                      style: { fontWeight: "bold" },
                    }}
                  />
                  <ListItemText
                    sx={{ fontWeight: "bold" }}
                    primary={`${article.content}`}
                  />
                </ListItem>
                <Box
                  sx={{
                    height: "2rem",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginRight: ".5rem",
                  }}
                >
                  <RocketLaunch color="primary" />
                </Box>
              </Paper>
            </ListItemButton>
          );
        })}
      </List>
    </>
  );
}

const PostData = [
  {
    title: "What is an Eclipse?",
    link: "what-is-an-eclipse",
    category: "trails",
    subTitle:
      "Ever wonder what an eclipse is? Here's everything you ever needed to know about eclipses.",
    content: [
      {
        text: `What is a Lunar Eclipse?

      A lunar eclipse, also known as an eclipse of the moon, happens when the Earth lines up just right and gets in between the light from the Sun to the Moon. This results in a phenomena known as a lunar eclipse. The lunar eclipse we are actually seeing is the Earth's shadow on the Moon!
      
      What is a Total Lunar Eclipse?
      
      When only a little of the Earth's shadow is visible on the Moon, this is known as a partial lunar eclipse. If the Earth's shadow blocks out all the light from the Sun to the Moon, this is known as a total lunar eclipse. Total lunar eclipses are very rare and require being in the right spot on Earth at the right time!
      
      What is a Solar Eclipse?
      
      A solar eclipse is kind of like the opposite of a lunar eclipse and happens when the Moon's orbit puts the Moon directly between the Earth and the Sun. This blocks the light from the Sun to the Earth and we see the Moon's shadow on the Earth!
      
      What is a Total Solar Eclipse?
      
      Depending on your viewing location during a solar eclipse, you will either see a partial solar eclipse or a total solar eclipse. A total solar eclipse happens when you are able to see the darkest part of the Moon's shadow!
      
      Are you more likely to see a Solar Eclipse or a Lunar Eclipse?
      
      While solar eclipses happen at about the same rate as lunar eclipses, it's much harder to see a solar eclipse than a lunar eclipse. The reason for this comes down to the size difference between the Earth and the Moon. Since a lunar eclipse lets us see the shadow of the Earth and a solar eclipse lets us see the shadow of the Moon, the size of the shadows we see are very different in size! This means that your chance of seeing a solar eclipse is much smaller since the Moon blocks out much less light from the Sun during a solar eclipse.
      
      How long do Eclipses last?
      
      Lunar eclipses can potentially last a few hours. Solar eclipses, on the other hand, generally only last a few minutes. This is because during a lunar eclipse we're seeing the Earth's shadow which is much larger than the Moon's shadow that we see during a solar eclipse. This also means that the area where the eclipses are visible is much larger during a lunar eclipse which is also why you're more likely to see a lunar eclipse than a solar eclipse.
      
      Is it safe to look at an Eclipse?
      
      It is safe to look at a lunar eclipse with the naked eye. This is NOT the case with solar eclipses and can result in blindness if you view a solar eclipse with the naked eye! Solar eclipses happen when the Moon directly blocks the light we receive from the Sun, so any sunlight that isn't being blocked is essentially the same as staring directly at the Sun! It is very important to wear eye protection specifically designed for solar eclipses if you plan on viewing a solar eclipse!
      
      What is the Umbra and Penumbra?
      
      These are terms used to describe the darker and lighter parts of a shadow. An umbra is the darkest part of the shadow where a penumbra is the lighter part of the shadow. If you're witnessing a total eclipse you are in the umbra. If you're experiencing a partial eclipse you are in the penumbra. In a total solar eclipse, you would be experiencing the Moon's umbra. If it's a partial solar eclipse, you would be in the Moon's penumbra. The same holds true for total lunar eclipse where you would be experiencing the Earth's umbra and a partial lunar eclipse which would be the Earth's penumbra.`,
      },
    ],
    ctaImage: "images/solar-eclipse/total-solar-eclipse-cta.jpeg",
    ctaAltText: "Moon at full solar eclipse",
    tags: ["moon"],
    date: new Date("September 5 2022"),
  },
  {
    title: "What is a Blood Moon?",
    link: "blood-moon",
    category: "moon",
    subTitle:
      "Why does the moon turn red sometimes? Experience the eerie allure of the blood moon.",
    content: [
      {
        alt: `A super red blood moon.`,
        path: "images/blood-moon/blood-moon-cta.jpeg",
      },
      {
        text: `What is a Blood Moon?\nAs ancient Mayans feared, Blood Moons aren't Moons with something monstrous and evil eating them; it's a trick of the light. Let me explain, what is a Blood Moon?\nBlood Moon is another term for a Total lunar eclipse. This eclipse changes the Moon's color. How? During a total lunar eclipse, the Earth comes between the Sun and the Moon in a row, preventing some light rays from reaching the Moon, but which light rays make their way to the Moon are reddish.\n
        We see the Moon from the Earth not wholly black. It gets a color that is orange-red or ruddy brown over a few hours.
        The phenomenon that a blood moon follows is the same as how we see sunrises and sunsets, as they are red too.
        Before discussing a Blood Moon, let's dive into the basics.\n\nWhy is it called the "Blood" Moon?\n
        The Moon appears to have a reddish tinge in the Earth's shadow. Hence, we call it the Blood Moon. The color is not the same as blood. It has an orange or coppery hue that a complete Moon projects.
        The air molecules in the Earth's atmosphere scatter out blue wavelengths. The rest of the light, reaching from the edges of the Earth, projects the Moon with a red glow.

        What makes them appear red?\n
        When the total lunar eclipse occurs, the Earth prevents direct sunlight from reaching the Moon. However, not all of this light is blocked; some of it does achieve the Moon but indirectly. By indirectly, I mean through the Earth's atmosphere.\n
        The Earth's atmosphere allows light rays to pass through a spectrum that filters out selective colors, precisely those preceding the violet wavelength. In this filtration, called the Raleigh scattering, the red wavelength is least affected as it is longer than the blue wavelength. So the blue wavelengths scatter in the atmosphere while the red wavelengths refract or bend towards the Moon.\n
        Hence, giving the Moon a reddish color. Sometimes, depending on the atmospheric composition, varied colors get filtered, leaving the Moon with yellow, orange, or coppery brown color like that of a penny.\n
        Do you know that the same effect is implied during sunrise and sunset? Yes, the orange, yellow, and purple colors we observe during them are due to the same effect, giving them a mixed glow.

        On which factors does this red glow depend?
        The brightness and the visibility of the red glow of the Moon depend on the following factors in the Earth's atmosphere:
        Dust.
        Clouds.
        Volcanic activities can affect the hue of the Moon. The smoke and ash block enough light to make the Moon appear darker red or black.\n`,
      },
      {
        alt: "Blood moon in transition from regular moon",
        path: "images/blood-moon/blood-transition.jpeg",
      },
      {
        text: `Lunar Eclipses:\n
        We see the Moon from Earth since something is illuminating it with light, i.e., the Sun. When this light source gets blocked by an object (the Earth), it also blocks the view.
        A lunar eclipse occurs when the Earth, the Sun, and the Moon come in a row and align together. This alignment makes the Moon come in the shadow of the Earth.

        Conditions of a lunar eclipse:
        Happens only during a full Moon.
        It occurs after every 29.5 days or when the Moon completes its orbit around the Sun.
        This only happens for some months as the orbit of the Moon is tilted in an elongated circle as it is elliptical, making it difficult for the Earth, Moon, and the Sun to align in a row.
        There are three types of Lunar eclipses: Total, partial, and penumbral lunar eclipses.

        What is the total lunar eclipse?
        It is essential to know the two types of shadows that the Earth casts when discussing what a Blood Moon is. The two types are the smaller, darkest shade (umbra) and the more prominent, lighter shadow (penumbra). In a total lunar eclipse, the Moon comes in the shadow of the Earth ultimately, projecting it into the darkest part of the shadow known as the umbra (falling the temperature from 100°C in the light to -150°C in this shadow). Within this umbra (shadow), we see the Moon in a reddish tone, and because of this phenomenon, we call this Moon the Blood Moon.`,
      },
      {
        alt: "Phases of a blood moon from a transition of a regular moon.",
        path: "images/blood-moon/blood-moon-phases.jpeg",
      },
      {
        text: `What is a Super Blood Moon?\n
        The word "super" indicates that something in this phenomenon is extraordinary. On top of the reddish color of the Blood moon, super blood moons are bigger in size and much brighter.
        Why super? What do you think makes this super big and bright? The Moon rotates around the Earth in a circle or an orbit. This rotation has points of contact. Sometimes the Moon is farther or distant from the Earth, a situation called apogee. While at other times, the contact issue is nearer and closer to the Earth, called perigee.
        When the full Moon is at its closest point to the Earth, it is called SuperMoon. We see this Super Moon as tighter, bigger, and brighter than usual.

        What does the Earth look like during the Blood Moon?\n
        If we go to the Lunar surface, we'll see a fire ring surrounding the Earth. When we know this ring, there are sunsets or sunrises in our view everywhere we see this ring.
        The red color we see during a Blood moon is also a combination of sunrises and sunsets. Simply put, this Moon is red because the sunrises and sunsets on the Earth are also red.

        Final words:
        I hope the concept is now clear; what is a Blood Moon? All in all, Blood Moons are nothing to be afraid of, nor is it the end of the world. We should enjoy the view as it's a rare astronomical phenomenon and a magnificent view.
        Usually, we are in awe of sunsets and sunrises because of the amalgamation of different colors. Likewise, Blood Moons also have beautiful color hues like orange, brown, red, and coppery color. So, lift your head and gaze at it in wonder!`,
      },
    ],
    ctaImage: "images/blood-moon/blood-moon-cta.jpeg",
    ctaAltText: "A super red blood moon.",
    tags: ["moon"],
    date: new Date("March 18 2023"),
  },
  {
    title: "What are the phases of the moon?",
    link: "phases-of-the-moon",
    category: "moon",
    subTitle:
      "What are the 8 phases of the Moon? Embrace the ever-changing beauty of the Moon's phases",
    content: [
      {
        alt: `3 phases of the moon shown side-by-side.`,
        path: "images/phases-of-the-moon/phase-3.jpeg",
      },
      {
        text: `The Moon's phases refer to the appearance of the illuminated portion of the Moon as seen from Earth. The Moon goes through a cycle of phases roughly once every 29.5 days, known as a lunar month. The following are the eight main phases of the Moon:

        1. New Moon: The Moon is not visible, as it is completely dark and not illuminated by the Sun.
        
        2. Waxing Crescent: A tiny sliver of the Moon is visible as it grows and moves further away from the Sun.
        
        3. First Quarter: Half of the Moon is visible as it grows larger and moves away from the Sun.
        
        4. Waxing Gibbous: More than half, but not quite all, of the Moon is visible as it grows and moves away from the Sun.
        
        5. Full Moon: The entire illuminated side of the Moon is visible, as it is directly opposite the Sun in the sky.
        
        6. Waning Gibbous: More than half, but not quite all, of the Moon is visible as it begins to shrink and move closer to the Sun.
        
        7. Third Quarter: Half of the Moon is visible as it continues to shrink in size and move closer to the Sun.
        
        8. Waning Crescent: A tiny sliver of the Moon is visible as it shrinks and moves closer to the Sun.
        
        After the waning crescent phase, the Moon returns to the new moon phase, and the cycle starts again.\n`,
      },
      {
        alt: "A moon peaking from the shadows.",
        path: "images/phases-of-the-moon/hiding-moon.jpeg",
      },
      {
        text: `The new moon phase is a lunar phase that occurs when the Moon is located between the Earth and the Sun so that the side of the Moon facing the Earth is not illuminated by sunlight. During this phase, the Moon appears dark, and the night sky is relatively moonlight-free.

        The new moon phase marks the beginning of a new lunar cycle, typically occurring every 29.5 days. It is sometimes referred to as the "dark moon" phase, and it is an important time in many cultures and traditions, particularly those that follow lunar calendars.
        
        During the new moon phase, the Moon's gravitational pull combines with the Sun's, resulting in a higher tidal activity. The higher tidal action can cause stronger ocean currents and tides, impacting coastal regions.`,
      },
      {
        alt: "Phases of a the moon shown side-by-side.",
        path: "images/phases-of-the-moon/phases-of-the-moon.jpeg",
      },
      {
        text: `The waxing crescent is a lunar phase that occurs shortly after the new moon phase. During this phase, a tiny sliver of the Moon's illuminated side becomes visible from Earth as the Moon moves out of its position between the Earth and Sun.

        The waxing crescent phase is so named because the illuminated portion of the Moon appears to be "waxing," or growing larger each night, as more of its surface is lit by the Sun. This phase typically lasts from three to seven days and is considered a time of new beginnings and growth.
        
        During the waxing crescent phase, the Moon rises during the day and sets in the early evening, so it is often visible in the sky during the late afternoon or early evening hours. As the phase progresses, the Moon's illuminated portion becomes more prominent and visible until it reaches the first quarter phase.`,
      },
      {
        alt: `All the phases of the moon shown side-by-side.`,
        path: "images/phases-of-the-moon/moon-all-phases.jpeg",
      },
      {
        text: `The first quarter is a lunar phase approximately one week after the new moon phase. During this phase, the Moon has completed about one-quarter of its orbit around the Earth, and the illuminated portion of the Moon's surface appears to be increasing in size.

        The first quarter phase is so named because the Moon appears to be one-quarter of the way through its monthly cycle of phases. During this phase, the Moon is visible in the sky for much of the afternoon and evening, and it is often visible in the sky during the late afternoon and early evening hours.
        
        The first quarter phase is a time of action, growth, and progress, and it is associated with setting goals, making plans, and taking action to move forward. It is also a time of challenge, as the Moon's increasing illumination may create some conflict or tension as we work to achieve our goals.
        
        After the first quarter phase, the Moon continues to wax or grow in illumination until it reaches the complete moon phase, which occurs approximately two weeks after the new moon phase.`,
      },
      {
        alt: `3 phases of the moon shown side-by-side.`,
        path: "images/phases-of-the-moon/phase-3.jpeg",
      },
      {
        text: `The full moon phase typically occurs approximately two weeks after the new moon phase and is the midpoint of the lunar cycle. It is a time of heightened energy, emotions, and intuition and has long been associated with various cultural and spiritual traditions.

        The full Moon is often associated with transformation, completion, and manifestation. It is a time when the intentions set at the new Moon may come to fruition, and it is also a time for releasing any negative energy or emotions that may be holding us back.
        
        The full Moon is a popular time for ceremonies, rituals, and meditation in many cultures and traditions. It is also associated with increased physical and mental activity and changes in behavior and mood in some individuals.`,
      },
      {
        alt: "A moon peaking from the shadows.",
        path: "images/phases-of-the-moon/hiding-moon.jpeg",
      },
      {
        text: `The waning crescent phase is so named because the illuminated portion of the Moon appears to be "waning" or decreasing in size each night. This phase typically lasts from three to seven days, and it is a time of letting go and releasing as we prepare for a new lunar cycle to begin.

        During the waning crescent phase, the Moon rises early and sets during the day, often visible in the sky just before dawn. As the stage progresses, the illuminated portion of the Moon's surface becomes smaller and less visible until it disappears entirely at the new moon phase.
        
        The waning crescent phase is associated with endings, completion, and the shedding of old habits or patterns. It is a time to reflect on the previous lunar cycle and let go of anything that no longer serves us to make space for new growth and beginnings in the upcoming cycle.`,
      },
    ],
    ctaImage: "images/phases-of-the-moon/moon-all-phases.jpeg",
    ctaAltText: "All the phases of the moon shown side-by-side.",
    tags: ["moon"],
    date: new Date("March 21 2023"),
  },
  {
    title: "How was the Moon created?",
    link: "how-was-the-moon-created",
    category: "moon",
    subTitle: "Exploring the age-old mystery of the Moon's formation.",
    content: [
      {
        alt: `3 phases of the moon shown side-by-side.`,
        path: "images/phases-of-the-moon/phase-3.jpeg",
      },
      {
        text: `The prevailing scientific theory about the formation of the Moon is that it was created by a giant impact between the early Earth and a Mars-sized object, sometimes called Theia, around 4.5 billion years ago. The effect would have been highly energetic, causing Theia and the early Earth to melt and partially vaporize. Some of the debris ejected into space from this collision eventually merged to form the Moon.`,
      },
      {
        alt: "Blood moon in transition from regular moon",
        path: "images/blood-moon/blood-transition.jpeg",
      },
      {
        text: `This theory is supported by several lines of evidence, including the similarities in composition between the Earth and Moon, the Moon's lack of iron and volatile elements, and the Moon's immense size compared to other moons in the Solar System relative to the size of their host planets. Additionally, computer simulations of such an impact scenario are consistent with what we observe today in terms of the size, orbit, and composition of the Moon.`,
      },
      {
        alt: "Phases of a blood moon from a transition of a regular moon.",
        path: "images/blood-moon/blood-moon-phases.jpeg",
      },
      {
        text: `The age of the Moon is estimated to be about 4.5 billion years old, roughly the same age as the rest of the Solar System. This estimate is based on various scientific methods, including radiometric dating of Moon rocks and analysis of the formation and evolution of the Solar System. The Moon is believed to have formed from debris left over after a Mars-sized object collided with the early Earth and has been orbiting the Earth ever since.`,
      },
      {
        alt: `All the phases of the moon shown side-by-side.`,
        path: "images/phases-of-the-moon/moon-all-phases.jpeg",
      },
      {
        text: `The Moon orbits the Earth in an elliptical path, with an average distance of about 238,855 miles (384,400 kilometers) between the two bodies. The Moon's orbit is not a perfect circle, so its distance from the Earth varies slightly throughout its orbit. At its closest point (perigee), the Moon can be about 225,623 miles (363,104 kilometers) away from the Earth, while at its farthest point (apogee), it can be about 251,097 miles (405,696 kilometers) away.`,
      },
      {
        alt: `A super red blood moon.`,
        path: "images/blood-moon/blood-moon-cta.jpeg",
      },
      {
        text: `The Moon's orbit takes approximately 27.3 days to complete, the length of its sidereal month (the time it takes to return to the same position relative to the fixed stars). However, because the Earth is also moving in its orbit around the Sun during this time, it takes the Moon a little longer to return to the same phase as viewed from Earth, resulting in a slightly longer lunar synodic month of approximately 29.5 days.`,
      },
    ],
    ctaImage: "images/phases-of-the-moon/hiding-moon.jpeg",
    ctaAltText: "A moon peaking from the shadows.",
    tags: ["moon"],
    date: new Date("March 29 2023"),
  },
  {
    title: "What is a comet?",
    link: "what-is-a-comet",
    category: "comet",
    subTitle: "Comets: The cosmic nomads of our solar system.",
    content: [
      {
        alt: "A comet overhead an angled view of the horizon.",
        path: "images/comets/comet-angled.jpeg",
      },
      {
        text: `A comet is a small celestial body of ice, dust, and gas. Comets are believed to be remnants of the formation of the solar system. They typically have highly elliptical orbits that bring them close to the Sun at their closest approach, also known as perihelion. As a comet approaches the Sun, its surface vaporizes, releasing gas and dust into space, creating a visible coma (a fuzzy cloud-like structure) around the nucleus. 
        
        The coma is a cloud of gases that surrounds the nucleus of a comet. The coma may also form a tail, which points away from the Sun due to the pressure of the solar wind. Comets can be observed from Earth with the naked eye and have been celebrated throughout history, with some comets being associated with significant events and omens in various cultures.
        
        Comets are thought to have formed during the early stages of the solar system's development, about 4.6 billion years ago. It is believed that comets originated from the Kuiper Belt and the Oort Cloud, regions of the outer solar system rich in icy bodies.`,
      },
      {
        alt: "A purplish-white comet streaking through the sky.",
        path: "images/comets/purp-white-comet.jpeg",
      },
      {
        text: `The Kuiper Belt is a disk-shaped region of space beyond Neptune's orbit, and it contains many small, icy bodies, including dwarf planets such as Pluto. The Oort Cloud is a spherical cloud of icy bodies surrounding the solar system at about 50,000 astronomical units (AU) from the Sun.

        It is believed that the gravitational interactions between the giant planets, particularly Jupiter and Saturn, caused some of the icy bodies in the Kuiper Belt and Oort Cloud to be perturbed into orbits that brought them closer to the Sun. As these bodies approached the Sun, they began to vaporize, releasing gas and dust, forming the comets' characteristic coma and tails.
        
        Over time, repeated passes around the Sun may cause the comets to lose some volatile materials and change their composition. This can lead to the formation of a crust, which protects the underlying volatile ice from further sublimation, making the comet appear more like a rocky asteroid.`,
      },
      {
        alt: "Multiple comets shooting through the sky.",
        path: "images/comets/multiple-comets.jpeg",
      },
    ],
    ctaImage: "images/comets/purp-white-comet.jpeg",
    ctaAltText: "A purplish-white comet streaking through the sky.",
    tags: ["comet"],
    date: new Date("April 5 2023"),
  },
];

module.exports = PostData;

import * as React from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import { ContentProps } from "../../common/types";

interface Props {
  currPost: ContentProps;
}
const imageStyles = { width: "100%" };

export function Post(props: Props) {
  return (
    <Container component={"section"} sx={{ marginTop: "5rem" }}>
      <Box
        component={"section"}
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          <Paper
            elevation={1}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              align="left"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              {props.currPost.title}
            </Typography>
          </Paper>
          {props.currPost.content.map((item, i) => {
            return item.path ? (
              <Paper
                alt={item.alt}
                component="img"
                src={`${item.path}`}
                sx={
                  item.styles ? { ...imageStyles, ...item.styles } : imageStyles
                }
                elevation={0}
                key={i}
              ></Paper>
            ) : (
              <Paper key={i} elevation={1} sx={{ padding: "1rem" }}>
                <Typography
                  variant="body2"
                  align="left"
                  sx={{
                    whiteSpace: "pre-line",
                    fontWeight: "bold",
                  }}
                >
                  {item.text}
                </Typography>
              </Paper>
            );
          })}
        </>
      </Box>
    </Container>
  );
}

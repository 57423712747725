import React from 'react';
import {
  CircularProgress,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  dateOfEclipse: string
}

export const Timer = (props: Props) => {
  const { dateOfEclipse } = props;
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const formatTime = (uotKey: string, uotValue: number) => {
    const mapping = {
      days: 1000,
      hours: 24,
      minutes: 60,
      seconds: 60,
    } as any;
    //todo fix this for when days are over 1000
    return 100 - (Math.round(uotValue) / mapping[uotKey]) * 100;
  };

  useEffect(() => {
    const currentTime = new Date();
    const eclipseDate = new Date(dateOfEclipse);
    const updateCountdown = () => {
      const difference = eclipseDate.valueOf() - currentTime.valueOf();
      setDays(Math.floor(difference / 1000 / 60 / 60 / 24));
      setHours(Math.floor(difference / 1000 / 60 / 60) % 24);
      setMinutes(Math.floor(difference / 1000 / 60) % 60);
      setSeconds(Math.floor(difference / 1000) % 60);
    };
    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, [days, hours, minutes, seconds, dateOfEclipse]);

  return (
    <Stack
      spacing={1}
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        container
        spacing={1}
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <Grid item xs={12}>
          <LinearProgress
            variant='determinate'
            value={formatTime('days', days)}
            color='primary'
          />
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            variant='determinate'
            value={formatTime('hours', hours)}
            color='secondary'
          />
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            variant='determinate'
            value={formatTime('minutes', minutes)}
            color='error'
          />
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            variant='determinate'
            value={formatTime('seconds', seconds)}
            color='success'
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <Grid item>
          <Stack
            spacing={1}
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant='subtitle1' align='center'>
              Days: {days}
            </Typography>
            <CircularProgress
              variant='determinate'
              value={formatTime('days', days)}
              color='primary'
            />
          </Stack>
        </Grid>
        <Grid item>
          <Stack
            spacing={1}
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant='subtitle1' align='center'>
              Hours: {hours}
            </Typography>
            <CircularProgress
              variant='determinate'
              value={formatTime('hours', hours)}
              color='secondary'
            />
          </Stack>
        </Grid>
        <Grid item>
          <Stack
            spacing={1}
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant='subtitle1' align='center'>
              Minutes: {minutes}
            </Typography>
            <CircularProgress
              variant='determinate'
              value={formatTime('minutes', minutes)}
              color='error'
            />
          </Stack>
        </Grid>
        <Grid item>
          <Stack
            spacing={1}
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant='subtitle1' align='center'>
              Seconds: {seconds}
            </Typography>
            <CircularProgress
              variant='determinate'
              value={formatTime('seconds', seconds)}
              color='success'
            />
          </Stack>
        </Grid>
      </Grid>

    </Stack>
  );
};

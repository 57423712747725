import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CssBaseline } from "@mui/material";
import { themeConfig } from "./styles/theme";
import { NavMenu } from "./components";
import PostData from "./data/Posts";
import { LinksArray } from "./data/LinkObjects";
import { CountdownCard, CountdownCards, Footer, Page, Post } from "./views/";
import { ContentProps, CountdownProps, LinkProps } from "./common/types";
import { Countdowns } from "./data/Countdowns";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () => createTheme(themeConfig(prefersDarkMode)),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <NavMenu />

        <Routes>
          {PostData.map((post: ContentProps, i: number) => {
            return (
              <Route
                element={<Post currPost={post} />}
                key={i}
                path={`${post.link}`}
              />
            );
          })}
          {LinksArray.map((link: LinkProps, i: number) => {
            return (
              <Route
                element={<Page linkObject={link} />}
                key={i}
                path={`${link.link}`}
              />
            );
          })}
          {Countdowns.map((countdown: CountdownProps, i: number) => {
            return (
              <Route
                element={<CountdownCard currCountdown={countdown} />}
                key={i}
                path={`${countdown.link}`}
              />
            );
          })}
          <Route element={<CountdownCards />} path="/countdown" />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;

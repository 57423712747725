import * as React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Timer } from "../../components";

interface CountdownProps {
  content: string;
  date: string;
  link: string;
  title: string;
}
interface Props {
  currCountdown: CountdownProps;
}

export const CountdownCard = (props: Props) => {
  const { content, date, title } = props.currCountdown;
  return (
    <Container>
      <Box sx={{ padding: "1rem" }}>
        <Typography variant="h5" align="left" sx={{ marginBottom: ".5rem" }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          align="left"
          sx={{ whiteSpace: "pre-line", marginBottom: "1rem" }}
        >
          {content}
        </Typography>
        <Timer dateOfEclipse={date} />
      </Box>
    </Container>
  );
};

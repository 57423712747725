import * as React from "react";
import { LinksArray } from "../../data/LinkObjects";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Brightness4,
  RocketLaunch,
  RocketLaunchOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";

const drawerWidth = 180;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export function NavMenu() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box component="nav" sx={{ display: "flex" }}>
      <AppBar open={open}>
        <Toolbar component="nav">
          <ListItem component="li" sx={{ paddingLeft: "0" }}>
            <ListItemButton component="a" href="/" sx={{ padding: "0" }}>
              <RocketLaunch color="secondary" sx={{ fontSize: "3rem" }} />
              <Typography
                component="h1"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  color: "primary.contrastText",
                  fontWeight: "bold",
                  marginLeft: ".5rem",
                  marginTop: ".675rem",
                  marginBottom: "0",
                }}
              >
                Eclipse Countdown
              </Typography>
            </ListItemButton>
          </ListItem>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <Menu color="secondary" sx={{ fontSize: "2.5rem" }} />
          </IconButton>
        </Toolbar>
        <Box sx={{ bgcolor: "secondary.main", height: ".5rem" }}></Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader sx={{ backgroundColor: "secondary.main" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List component="nav">
          {LinksArray.map((link, index) => (
            <ListItem key={index} disablePadding divider={true}>
              <ListItemButton component="a" href={link.link}>
                <ListItemIcon>
                  {link.text === "Home" ? (
                    <RocketLaunchOutlined
                      sx={{ fontSize: "2rem" }}
                      color="primary"
                    />
                  ) : link.text === "Countdown" ? (
                    <Brightness4 sx={{ fontSize: "2rem" }} color="secondary" />
                  ) : (
                    ""
                  )}
                </ListItemIcon>
                <Typography component="h2" sx={{ marginBottom: "0" }}>
                  {link.text}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}

export const LinksArray = [
  {
    text: "Home",
    link: "/",
    ctaImagePath: "images/solar-eclipse/stages-of-solar-eclipse.jpeg",
    ctaAltText: "A total solar eclipse.",
  },
  {
    text: "Countdown",
    link: "/countdown",
    ctaImagePath: "images/solar-eclipse/solar-eclipse-orange.jpeg",
    ctaAltText: "A solar eclipse showing bright orange.",
  },
];

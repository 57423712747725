export const Countdowns = [
  {
    link: "australian-total-solar-eclipse",
    title: "When is the next Australian total solar eclipse?",
    content:
      "The next Australian total solar eclipse will take place on April 20th, 2023.",
    date: "April 20 2023 00:00:00",
  },
  {
    link: "north-american-total-solar-eclipse",
    title: "When is the next North American total solar eclipse?",
    content:
      "The next North American total solar eclipse will take place on April 8th, 2024.",
    date: "April 08 2024 00:00:00",
  },
  {
    link: "european-total-solar-eclipse",
    title: "When is the next European total solar eclipse?",
    content:
      "The next European total solar eclipse will take place on August 12th, 2026.",
    date: "August 12 2026 00:00:00",
  },
  {
    link: "african-total-solar-eclipse",
    title: "When is the next African total solar eclipse?",
    content:
      "The next African total solar eclipse will take place on August 2nd, 2027.",
    date: "August 2 2027 00:00:00",
  },
  {
    link: "arkansas-total-solar-eclipse",
    title: "When is the next Arkansas total solar eclipse?",
    content:
      "The next Arkansas total solar eclipse will take place on April 8th, 2024.",
    date: "April 08 2024 00:00:00",
  },
  {
    link: "texas-total-solar-eclipse",
    title: "When is the next Texas total solar eclipse?",
    content:
      "The next Texas total solar eclipse will take place on April 8th, 2024.",
    date: "April 08 2024 00:00:00",
  },
  {
    link: "california-total-solar-eclipse",
    title: "When is the next California total solar eclipse?",
    content:
      "The next California total solar eclipse will take place on April 8th, 2024.",
    date: "April 08 2024 00:00:00",
  },
  {
    link: "florida-total-solar-eclipse",
    title: "When is the next Florida total solar eclipse?",
    content:
      "The next Florida total solar eclipse will take place on April 8th, 2024.",
    date: "April 08 2024 00:00:00",
  },
  {
    link: "kentucky-total-solar-eclipse",
    title: "When is the next Kentucky total solar eclipse?",
    content:
      "The next Kentucky total solar eclipse will take place on April 8th, 2024.",
    date: "April 08 2024 00:00:00",
  },
  {
    link: "colorado-total-solar-eclipse",
    title: "When is the next Colorado total solar eclipse?",
    content:
      "The next Colorado total solar eclipse will take place on April 8th, 2024.",
    date: "April 08 2024 00:00:00",
  },
];
